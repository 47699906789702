@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('fonts/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('fonts/roboto-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/roboto-v27-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}



body {
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff; 
}

.content {
  background-image: url('../assets/Vector.png'); 
  background-size: contain; 
  background-repeat: no-repeat; 
  background-position: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

span {
  font-weight: bold; 
  font-size: 60px;
  color: #91867d;
  margin: -9px; 
}

.logo { margin: 18px 18px 0px 18px;
  width: 380px;
}

.empty {
  width: 350px;
}

.main {display: flex; flex-direction: column; margin-left: 15px; width: 100%; 
                
.box-grid {background-image: linear-gradient(to left, white, #777473); border-radius: 15px; padding:3px; width:100%; height: 31px; margin-top: 10px;}

.description {margin: 5px; font-size: 32px;}
}

.footer {
  display: flex; 
  position: relative;

.div-img 
{
  width: 200px; 
  position: absolute; 
  left: 89%; 
  img {width : 80%;}}


.div-center {
  background-color :#91867d; 
  width: 100%;
  padding-top: 5px;
  padding-bottom: 15px;
}
} 

.footer-title { 
  margin-left: 4px; 
  font-size: 28px;
  color:#fff; 

}

.icon {
  margin-left: 12px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  }
  
.colums {
  display: flex;
  justify-content: space-between;
 

  .divider {
    height: 100vh;
    margin: 0 26px;
    background-color: #91867d;
    border-left: 3px solid #91867d;
    border-radius: 10px;
  }

  .first-side {
    flex: 1;
  }
  .second-side {
    flex: 1;
  }
}
